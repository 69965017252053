import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import "../styles/gallery.scss";
import Heading from "../components/Heading";
import Seo from "../components/seo";

const Galeria = ({ data }) => {
  return (
    <>
      <Seo
        title="Galeria"
        description={`Galeria - Usługi transportowe Zbigniew Lidźbiński`}
        keywords={["galeria"]}
      />
      <section className="gallery">
        <div className="container">
          <Heading Tag="h2" text="Galeria" />
          <div className="images">
            <div className="row-1">
              <div className="left">
                <Image fluid={data.truck1.childImageSharp.fluid} />
              </div>
              <div className="right">
                <div className="first">
                  <Image fluid={data.truck2.childImageSharp.fluid} />
                  <Image fluid={data.truck3.childImageSharp.fluid} />
                </div>
                <div className="second">
                  <Image fluid={data.truck4.childImageSharp.fluid} />
                </div>
              </div>
            </div>
            <div className="row-2">
              <div className="left">
                <div className="first">
                  <Image fluid={data.truck5.childImageSharp.fluid} />
                  <Image fluid={data.truck6.childImageSharp.fluid} />
                </div>
                <div className="second">
                  <Image fluid={data.truck8.childImageSharp.fluid} />
                </div>
              </div>
              <div className="right">
                <div className="first">
                  <Image fluid={data.truck7.childImageSharp.fluid} />
                </div>
                <div className="second">
                  <Image fluid={data.truck9.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  {
    truck1: file(name: { eq: "gallery_truck_1" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck2: file(name: { eq: "gallery_truck_2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck3: file(name: { eq: "gallery_truck_3" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck4: file(name: { eq: "gallery_truck_4" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck5: file(name: { eq: "gallery_truck_5" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck6: file(name: { eq: "gallery_truck_6" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck7: file(name: { eq: "gallery_truck_7" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck8: file(name: { eq: "gallery_truck_8" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    truck9: file(name: { eq: "gallery_truck_9" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default Galeria;
